import GoogleIcon from '../../assets/icons/devicon_google.svg';

interface GoogleButtonProps {
    onClick?: () => void;
}

const GoogleButton = ({ onClick }: GoogleButtonProps) => {
    return (
        <button
            className="google-button"
            onClick={(e) => {
                e.preventDefault();
                if (onClick) onClick();
            }}
        >
            <img src={GoogleIcon} alt="Google Logo" className="google-icon" />
            <span>Continuar com o Google</span>
        </button>
    );
};

export default GoogleButton;

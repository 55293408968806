import axios from 'axios';

/**
 * Axios instance for API requests.
 * 
 * - Uses `VITE_BACKEND_URL` as the base URL.
 * - Ensures JSON communication with proper headers.
 * - Automatically includes authentication token from cookies if available.
 */

const api = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL.replace(/\/$/, ''),
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});


export default api;

import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext';
import AppRouter from './routes/Router';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Main application component.
 *
 * - Wraps the entire app with `AuthProvider` to manage authentication context.
 * - Provides global routing through `AppRouter`.
 * - Integrates `ToastContainer` for global toast notifications.
 */
const App = () => (
    <AuthProvider>
        <AppRouter />
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
        />
    </AuthProvider>
);

export default App;

import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import AuthLayout from '../../components/layout/AuthLayout';
import AuthForm from '../../components/forms/AuthForm';
import { toast } from 'react-toastify';
import '../../styles/pages/_login.scss';

/**
 * Recovery Password Page Component
 *
 * - Manages the full password recovery flow.
 * - Displays success/error messages via toast notifications.
 * - Handles loading and success states across steps.
 */
const RecoveryPassword = () => {
    const {
        recoverySendEmail,
        verifyRecoveryCode,
        changePassword
    } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    /**
     * Step 1: Handle sending recovery email to the user.
     * 
     * @param formData - Contains the email for password recovery.
     * @returns boolean - Whether the operation succeeded.
     */
    const handleRecovery = async (formData: Record<string, string>): Promise<boolean> => {
        setIsLoading(true);
        try {
            await recoverySendEmail({ emailRecovery: formData.emailRecovery });

            toast.success("Recovery email sent successfully! Please check your inbox (and spam) for the verification code. 📩");
            setIsSuccess(true);
            return true;
        } catch (error: any) {
            console.error("Error sending recovery email:", error);

            let errorMessage = "An error occurred while trying to send the recovery email. Please try again later.";

            if (error.response?.data?.message) {
                errorMessage = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            // Specific error handling based on HTTP status codes
            if (error.response?.status === 404) {
                errorMessage = "Email not found. Please double-check the entered address.";
            } else if (error.response?.status === 500) {
                errorMessage = "Internal server error. Please try again later.";
            } else if (error.response?.status === 429) {
                errorMessage = "Too many attempts. Please wait a few minutes before trying again.";
            }

            toast.error(errorMessage);
            setIsSuccess(false);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Step 2: Handle verification of the recovery code sent via email.
     * 
     * @param formData - Contains the recovery code to validate.
     * @returns boolean - Whether the code is valid.
     */
    const handleVerifyCode = async (formData: Record<string, string>): Promise<boolean> => {
        toast.info("Verifying code: " + formData.recoveryCode);
        setIsLoading(true);
        setIsSuccess(false);

        try {
            await verifyRecoveryCode({ recoveryCode: formData.recoveryCode });

            toast.success("Code successfully verified! 🔓");
            setIsSuccess(true);
            return true;
        } catch (error: any) {
            console.error("Error verifying recovery code:", error);

            let errorMessage = "An error occurred while validating the code. Please try again.";

            if (error.response?.data?.message) {
                const serverMessage = error.response.data.message;

                if (error.response.status === 400) {
                    errorMessage = serverMessage === "O token fornecido é inválido"
                        ? "The code provided is invalid or has expired. Please request a new one."
                        : serverMessage;
                } else if (error.response.status === 404) {
                    errorMessage = "No valid code found. Please check your email or request a new one.";
                } else if (error.response.status === 500) {
                    errorMessage = "Internal server error. Please try again later.";
                }
            }

            toast.error(errorMessage);
            setIsSuccess(false);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Step 3: Handle changing the user's password after verifying the code.
     * 
     * @param formData - Contains the new password and confirmation.
     * @returns boolean - Whether the password was successfully changed.
     */
    const handleChangePassword = async (formData: Record<string, string>): Promise<boolean> => {
        setIsLoading(true);
        setIsSuccess(false);

        try {
            // Basic field validation
            if (!formData.newPassword || !formData.confirmNewPassword) {
                toast.error("Please fill in all password fields.");
                return false;
            }

            if (formData.newPassword !== formData.confirmNewPassword) {
                toast.error("Passwords do not match.");
                return false;
            }

            if (formData.newPassword.length < 6) {
                toast.error("Password must be at least 6 characters long.");
                return false;
            }

            await changePassword({
                recoveryCode: formData.recoveryCode,
                newPassword: formData.newPassword,
            });

            toast.success("Password successfully changed! 🔐");
            setIsSuccess(true);
            return true;

        } catch (error: any) {
            console.error("Error changing password:", error);

            let errorMessage = "An error occurred while changing the password. Please try again.";

            if (error.response?.data?.message) {
                errorMessage = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            if (error.response?.status === 400) {
                errorMessage = error.response.data.message;
            }

            toast.error(errorMessage);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Main provider function that dynamically routes the form submission
     * to the appropriate handler function based on `action`.
     * 
     * @param formData - Contains form fields and action type.
     * @returns boolean - Whether the delegated action succeeded.
     */
    const handleProvider = async (formData: Record<string, string> & { action?: string }): Promise<boolean> => {
        const actionsMap: Record<string, (data: Record<string, string>) => Promise<boolean>> = {
            handleRecovery,
            handleVerifyCode,
            handleChangePassword
        };

        const actionName = formData.action || 'handleRecovery';
        const selectedAction = actionsMap[actionName];

        if (selectedAction) {
            return await selectedAction(formData);
        }

        console.warn(`Unknown action: ${actionName}`);
        toast.error("Unknown action. Please try again.");
        return false;
    };

    /**
     * Render layout and form component, passing states and handler.
     */
    return (
        <AuthLayout
            className='recovery-auth'
        >
            <AuthForm
                type="recovery"
                onSubmit={handleProvider}
                isLoading={isLoading}
                isSuccess={isSuccess}
            />
        </AuthLayout>
    );
};

export default RecoveryPassword;

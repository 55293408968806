import clinicImg from '../assets/dashboard/clinica.svg';
import consultaImg from '../assets/dashboard/consulta.svg';
import adminImg from '../assets/dashboard/admin.svg';
import suporteImg from '../assets/dashboard/suporte.svg';
import profissionalImg from '../assets/dashboard/profissional.svg';
import pacienteImg from '../assets/dashboard/paciente.svg';
import perguntaImg from '../assets/dashboard/pergunta.svg';

import IconClinicImg from '../assets/dashboard/icon/clinica.svg';
import IconConsultaImg from '../assets/dashboard/icon/consulta.svg';
import IconAdminImg from '../assets/dashboard/icon/admin.svg';
import IconSuporteImg from '../assets/dashboard/icon/suporte.svg';
import IconProfissionalImg from '../assets/dashboard/icon/profissional.svg';
import IconPacienteImg from '../assets/dashboard/icon/paciente.svg';
import IconPerguntaImg from '../assets/dashboard/icon/pergunta.svg';

export interface DashboardCardConfig {
    title: string;
    subtitle: string[];
    path: string;
    image: string;
    icon?: string;
    area?: string;
    tall?: boolean;
}

export const cardsConfig: Record<'admin', DashboardCardConfig[]> = {
    admin: [
        {
            title: 'Clínicas',
            image: clinicImg,
            icon: IconClinicImg,
            path: '/admin/clinicas',
            subtitle: ['29 Cadastros', '0 Desativados'],
            area: 'clinicas'
        },
        {
            title: 'Consultas',
            image: consultaImg,
            icon: IconConsultaImg,
            path: '/admin/consultas',
            subtitle: ['44 Entradas ao Total', '29 Consultas', '15 Retornos', '0 Canceladas', '28 Pagas'],
            area: 'consultas',
            tall: true
        },
        {
            title: 'Admin',
            image: adminImg,
            icon: IconAdminImg,
            path: '/admin/admins',
            subtitle: ['2 Entradas', '2 Ativos'],
            area: 'admin'
        },
        {
            title: 'Suporte',
            image: suporteImg,
            icon: IconSuporteImg,
            path: '/admin/suporte',
            subtitle: ['2 Entradas', '2 Ativos'],
            area: 'suporte',
            tall: true
        },
        {
            title: 'Profissionais',
            image: profissionalImg,
            icon: IconProfissionalImg,
            path: '/admin/profissionais',
            subtitle: ['29 Cadastros', '0 Desativados', '19 Psicólogos', '10 Psiquiatras'],
            area: 'profissionais'
        },
        {
            title: 'Pacientes',
            image: pacienteImg,
            icon: IconPacienteImg,
            path: '/admin/pacientes',
            subtitle: ['29 Cadastros', '0 Desativados'],
            area: 'pacientes'
        },
        {
            title: 'Perguntas',
            image: perguntaImg,
            icon: IconPerguntaImg,
            path: '/admin/perguntas',
            subtitle: ['29 Entradas', '39 Acessos'],
            area: 'perguntas'
        }
    ]
};


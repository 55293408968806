import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../components/layout/AuthLayout';
import AuthForm from '../../components/forms/AuthForm';
import { toast } from 'react-toastify';
import '../../styles/pages/_login.scss';

/**
 * Login Page Component
 *
 * - Handles user authentication.
 * - Displays success/error messages via toast notifications.
 * - Manages loading and success states.
 */
const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    /**
     * Handles the login process with API call and state updates.
     * @param formData User login credentials (email and password).
     * @returns {Promise<boolean>} Indicates whether login was successful.
     */
    const handleLogin = async (formData: Record<string, string>): Promise<boolean> => {
        setIsLoading(true);
        try {
            await login(formData.email, formData.password);

            toast.success("Login realizado com sucesso! 🚀");


            setTimeout(() => navigate('/home'), 1000);
            return true;
        } catch (error: any) {
            console.error("Erro no login:", error);

            let errorMessage = "Erro ao realizar login. Verifique suas credenciais.";

            if (error.response?.data?.message) {
                errorMessage = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            toast.error(errorMessage);

            setIsLoading(false);
            setIsSuccess(false);
            return false;
        }
    };

    return (
        <AuthLayout>
            <AuthForm
                type="login"
                onSubmit={handleLogin}
                isLoading={isLoading}
                isSuccess={isSuccess}
            />
        </AuthLayout>
    );
};

export default Login;

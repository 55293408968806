import Input from "./Input";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { icons } from "../../utils/icons";
import { AuthStepConfig } from "../../config/authConfig";
import {
    validateEmail,
    validateName,
    validateDate,
    validatePhone,
    validateCPF,
    validatePassword,
    validateConfirmPassword,
    validateRecoveryCode
} from '../../utils/validations';

interface AuthStepProps {
    stepData: AuthStepConfig;
    formData: Record<string, string>;
    errors: Record<string, boolean>;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleNextStep: () => void;
    isLoading: boolean;
    onSubmit: (formData: Record<string, string>) => Promise<boolean | void>;
    isSuccess: boolean;
}

/**
 * AuthStep component
 * 
 * - Renders the input fields and buttons for a given auth step.
 * - Validates each input field based on its name.
 * - Maps button actions dynamically to functions (submit, verify, next, etc).
 */
const AuthStep: React.FC<AuthStepProps> = ({
    stepData,
    formData,
    errors,
    handleInputChange,
    handleNextStep,
    isLoading,
    onSubmit,
}) => {
    const navigate = useNavigate();

    /**
     * Validates individual field based on its name.
     * 
     * @param name - Field name.
     * @param value - Current input value.
     * @returns boolean indicating whether the input is valid.
     */
    const isValid = (name: string, value: string): boolean => {
        switch (name) {
            case "email":
            case "emailRecovery":
                return validateEmail(value);
            case "firstName":
            case "lastName":
                return validateName(value);
            case "dateOfBirth":
                return validateDate(value);
            case "phone":
                return validatePhone(value);
            case "cpf":
                return validateCPF(value);
            case "registerPassword":
            case "newPassword":
            case "password":
                return validatePassword(value);
            case "confirmRegisterPassword":
                return validateConfirmPassword(formData.registerPassword, value);
            case "confirmNewPassword":
                return validateConfirmPassword(formData.newPassword, value);
            case "recoveryCode":
                return validateRecoveryCode(value);
            default:
                return false;
        }
    };

    /**
     * Map of action names to their corresponding handlers.
     * 
     * - `onSubmit`: Triggers handleRecovery.
     * - `verifyCode`: Triggers code verification.
     * - `changePassword`: Triggers password change.
     * - `nextStep`: Advances to the next step.
     * - `goToLogin`: Navigates to the login page.
     */
    const actionsMap: Record<string, () => void | Promise<void>> = {
        nextStep: handleNextStep,
        onSubmit: () => {
            formData.action = 'handleRecovery';
            void onSubmit(formData);
        },
        verifyCode: () => {
            formData.action = 'handleVerifyCode';
            void onSubmit(formData);
        },
        changePassword: () => {
            formData.action = 'handleChangePassword';
            void onSubmit(formData);
        },
        goToLogin: () => {
            navigate('/login');
        },
    };

    return (
        <>
            {stepData.fields.map((field) => (
                <Input
                    key={field.name}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    placeholder={field.placeholder || ""}
                    icon={<img src={icons[field.icon]} />}
                    isValid={isValid(field.name, formData[field.name] || "")}
                    className={`
                        ${errors[field.name] ? 'error' : ''}
                        ${['emailRecovery', 'recoveryCode'].includes(field.name) ? 'emailRecoveryInput' : ''}
                    `}
                />
            ))}

            {stepData.buttons.map((btn) => {
                const action = btn.action || 'onSubmit';

                return (
                    <Button
                        key={btn.text}
                        text={btn.text}
                        type={btn.type}
                        onClick={actionsMap[action]}
                        isLoading={isLoading}
                        icon={btn.icon ? <img src={icons[btn.icon]} /> : undefined}
                    />
                );
            })}
        </>
    );
};

export default AuthStep;

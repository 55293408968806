import {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode
} from 'react';

import {
    login as apiLogin,
    loginAdmin as apiLoginAdmin,
    logout as apiLogout,
    register as apiRegister,
    recoverySendEmail as apiRecoverySendEmail,
    verifyRecoveryCode as apiVerifyRecoveryCode,
    changePassword as apiChangePassword
} from '../api/auth';

import Cookies from 'js-cookie';

/**
 * Interface representing user or admin information.
 */
interface User {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    isAdmin?: boolean;
}

/**
 * Interface representing user registration data.
 */
interface RegisterData {
    first_name: string;
    last_name: string;
    cpf: string;
    email: string;
    password: string;
    phone_number: string;
    birthday: string;
}

/**
 * Interface for recovery email request payload.
 */
interface RecoverySendEmailData {
    emailRecovery: string;
}

/**
 * Interface for recovery code verification payload.
 */
interface VerifyRecoveryCodeData {
    recoveryCode: string;
}

/**
 * Interface for password change payload.
 */
interface ChangePasswordData {
    recoveryCode: string;
    newPassword: string;
}

/**
 * Interface representing the shape of the authentication context.
 */
interface AuthContextType {
    user: User | null;
    setUser: (user: User | null) => void;
    isLoading: boolean;
    login: (email: string, password: string) => Promise<void>;
    loginAdmin: (email: string, password: string) => Promise<void>;
    register: (userData: RegisterData) => Promise<void>;
    recoverySendEmail: (data: RecoverySendEmailData) => Promise<void>;
    verifyRecoveryCode: (data: VerifyRecoveryCodeData) => Promise<void>;
    changePassword: (data: ChangePasswordData) => Promise<void>;
    logout: () => Promise<void>;
}


// Creating the authentication context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * AuthProvider component
 *
 * - Wraps the app with authentication context.
 * - Manages login state for both users and admins.
 * - Persists session data using cookies.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Loads the authenticated user or admin from cookies on mount.
     */
    useEffect(() => {
        const adminId = Cookies.get('admin_auth_token');
        const adminInfos = Cookies.get('admin_infos');

        const userId = Cookies.get('auth_token');
        const userInfos = Cookies.get('user_infos');

        try {
            if (adminId && adminInfos) {
                const admin = JSON.parse(adminInfos);
                setUser({ ...admin, isAdmin: true });
            } else if (userId && userInfos) {
                const parsedUser = JSON.parse(userInfos);
                setUser(parsedUser);
            }
        } catch (e) {
            console.error('Failed to parse auth cookies', e);
        }

        setIsLoading(false);
    }, []);

    /**
     * Logs in a regular user and stores credentials in cookies.
     * 
     * @param email - The user's email.
     * @param password - The user's password.
     */
    const login = async (email: string, password: string) => {
        const { user } = await apiLogin(email, password);
        Cookies.set('auth_token', user.id, { secure: true, sameSite: 'strict' });
        Cookies.set('user_infos', JSON.stringify(user), { secure: true, sameSite: 'strict' });
        setUser(user);
    };

    /**
     * Logs in an admin and stores credentials in cookies with admin flag.
     * 
     * @param email - The admin's email.
     * @param password - The admin's password.
     */
    const loginAdmin = async (email: string, password: string) => {
        const { admin } = await apiLoginAdmin(email, password);
        Cookies.set('admin_auth_token', admin.id, { secure: true, sameSite: 'strict' });
        Cookies.set('admin_infos', JSON.stringify(admin), { secure: true, sameSite: 'strict' });
        setUser({ ...admin, isAdmin: true });
    };

    /**
     * Registers a new user and stores credentials in cookies.
     * 
     * @param userData - The user's registration data.
     */
    const register = async (userData: RegisterData) => {
        const { user } = await apiRegister(userData);
        Cookies.set('auth_token', user.id, { secure: true, sameSite: 'strict' });
        Cookies.set('user_infos', JSON.stringify(user), { secure: true, sameSite: 'strict' });
        setUser(user);
    };

    /**
     * Sends a recovery email to the user.
     * 
     * @param data - Email data to send recovery instructions.
     */
    const recoverySendEmail = async (data: RecoverySendEmailData) => {
        const { success } = await apiRecoverySendEmail(data);
        console.log(success);
    };

    /**
     * Verifies the recovery code sent to the user's email.
     * 
     * @param data - Recovery code input.
     */
    const verifyRecoveryCode = async (data: VerifyRecoveryCodeData) => {
        const { success } = await apiVerifyRecoveryCode(data);
        console.log(success);
    };

    /**
     * Changes the user's password using a valid recovery code.
     * 
     * @param data - Recovery code and new password.
     */
    const changePassword = async (data: ChangePasswordData) => {
        const { success } = await apiChangePassword(data);
        console.log(success);
    };

    /**
     * Logs out the current user or admin and clears all auth cookies.
     */
    const logout = async () => {
        await apiLogout();
        Cookies.remove('auth_token');
        Cookies.remove('user_infos');
        Cookies.remove('admin_auth_token');
        Cookies.remove('admin_infos');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            isLoading,
            login,
            loginAdmin,
            register,
            recoverySendEmail,
            verifyRecoveryCode,
            changePassword,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Custom hook to access the AuthContext safely.
 * 
 * @throws Error if used outside of an AuthProvider.
 * @returns The AuthContext value.
 */
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

import TextureImage from '../../assets/images/texture.jpg';

interface ButtonProps {
    text: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    type?: 'button' | 'submit';
    isLoading?: boolean;
    template?: number;
    iconDirection?: string;
}

const Button = ({
    text,
    icon,
    onClick,
    type = 'button',
    isLoading,
    template = 1,
    iconDirection = 'left',
}: ButtonProps) => {
    const style = template === 1
        ? {
            '--texture-url': `url(${TextureImage})`,
        } as React.CSSProperties
        : undefined;

    return (
        <button
            type={type}
            onClick={onClick}
            className={`button ${template === 1 ? 'buttonTexture' : template === 2 ? 'buttonBlackWhite' : 'buttonDefault'} ${iconDirection}`}
            disabled={isLoading}
            style={style}
        >
            <span>{isLoading ? 'Carregando...' : text}</span>
            {icon && <span className="button-icon">{icon}</span>}
        </button>
    );
};


export default Button;

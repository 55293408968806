/**
 * Validates if an email is in a correct format.
 * @param {string} email - The email to be validated.
 * @returns {boolean} True if the email format is valid, false otherwise.
 */
export const validateEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
};

/**
 * Validates if a name has more than 3 meaningful characters.
 * @param {string} name - The name to be validated.
 * @returns {boolean} True if the name is valid, false otherwise.
 */
export const validateName = (name: string): boolean => {
    return name.trim().length > 3;
};

/**
 * Validates a phone number format (e.g., (XX) XXXXX-XXXX or (XX) XXXX-XXXX).
 * @param {string} phone - The phone number to be validated.
 * @returns {boolean} True if the phone number format is correct, false otherwise.
 */
export const validatePhone = (phone: string): boolean => {
    return /^\(\d{2}\) \d{4,5}-\d{4}$/.test(phone);
};

/**
 * Validates a CPF (Brazilian Individual Taxpayer Registry) number.
 * Ensures the CPF follows the correct structure and checksum validation.
 * @param {string} cpf - The CPF number to be validated.
 * @returns {boolean} True if the CPF is valid, false otherwise.
 */
export const validateCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

    const calcDV = (slice: number) => {
        let sum = 0;
        for (let i = 0; i < slice; i++) {
            sum += parseInt(cpf.charAt(i)) * (slice + 1 - i);
        }
        const rest = (sum * 10) % 11;
        return rest === 10 ? 0 : rest;
    };

    return calcDV(9) === parseInt(cpf.charAt(9)) && calcDV(10) === parseInt(cpf.charAt(10));
};

/**
 * Validates if a given date represents an individual who is at least 18 years old.
 * @param {string} date - The birthdate string to be validated.
 * @returns {boolean} True if the individual is 18 or older, false otherwise.
 */
export const validateDate = (date: string): boolean => {
    const birthDate = new Date(date);
    if (isNaN(birthDate.getTime())) return false;

    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    return (
        age > 18 || (age === 18 && today.getMonth() >= birthDate.getMonth() && today.getDate() >= birthDate.getDate())
    );
};

/**
 * Validates if a password meets security requirements.
 * The password must contain at least one uppercase letter, one digit, and be at least 6 characters long.
 * @param {string} password - The password to be validated.
 * @returns {boolean} True if the password meets security standards, false otherwise.
 */
export const validatePassword = (password: string): boolean => {
    return /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(password);
};

/**
 * Validates if the confirmed password matches the original password.
 * @param {string} password - The original password.
 * @param {string} confirmPassword - The confirmed password.
 * @returns {boolean} True if both passwords match, false otherwise.
 */
export const validateConfirmPassword = (password: string, confirmPassword: string): boolean => {
    return password === confirmPassword && validatePassword(password);
};

/**
 * Validates a recovery code, ensuring it has exactly 6 digits.
 * @param {string} value - The masked recovery code.
 * @returns {boolean} Whether the recovery code is valid.
 */
export const validateRecoveryCode = (value: string): boolean => {
    return /^\d{3}-\d{3}$/.test(value);
};

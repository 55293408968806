import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import IconMenuHamburguer from '../../assets/icons/menu-hamburguer.svg';
import IconProfileWhite from '../../assets/icons/icon-profile-white.svg';
import IconLogout from '../../assets/icons/iconLogout.svg';

import '../../styles/components/_menu_hamburguer.scss';


const MenuHamburguer = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <div className="menu-hamburguer-container">
            <button className="menu-button" onClick={toggleMenu}>
                <img src={IconMenuHamburguer} alt="Menu" />
            </button>

            {isOpen && (
                <div className="menu-dropdown">
                    <div className="menu-item">
                        <img src={IconProfileWhite} alt="Meu perfil" />
                        <span>Meu perfil</span>
                    </div>
                    <button
                        type="button"
                        onClick={handleLogout}
                        className="menu-item">
                        <img src={IconLogout} alt="Sair" />
                        <span>Sair</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default MenuHamburguer;

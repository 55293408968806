import { useNavigate } from 'react-router-dom';
import '../../styles/components/_dashboard_card.scss';

interface DashboardCardProps {
    title: string;
    subtitle?: string[];
    image: string;
    path: string;
    tall?: boolean;
    area?: string;
    icon?: string;
    children?: React.ReactNode;
}


const DashboardCard = ({ title, subtitle, image, tall = false, area, icon, path, children }: DashboardCardProps) => {
    const navigate = useNavigate();

    return (
        <div
            className={`dashboard-card area-${area} ${tall ? 'tall' : ''}`}
            onClick={() => navigate(path)}
        >
            <div className="dashboard-card-content">
                <div>
                    <h3>
                        {icon && <img src={icon} />}
                        {title}
                    </h3>
                    {subtitle && (
                        <ul>
                            {subtitle.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    )}
                    {children}
                </div>
                <img src={image} alt={title} />
            </div>
        </div>
    );
};

export default DashboardCard;

import { cardsConfig } from '../../../config/cardsConfig';
import DashboardCard from '../../../components/dashboard/DashboardCard';
import { useAuth } from '../../../context/AuthContext';
import Header from '../../../components/dashboard/Header';
import '../../../styles/pages/_dashboard.scss';

const Dashboard = () => {
    const { user } = useAuth();
    const cards = user?.isAdmin ? cardsConfig.admin : [];

    return (
        <div className="main-dashboard">
            <Header />
            <div className="main-content">
                {user?.isAdmin ? (
                    <div className="dashboard-grid">
                        {cards.map((card, index) => (
                            <DashboardCard key={index} {...card} />
                        ))}
                    </div>
                ) : (
                    <h1>Você está inscrito na lista de espera</h1>
                )}
            </div>
        </div>
    );
};

export default Dashboard;

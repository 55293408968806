import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

/**
 * PrivateRoute Component
 * 
 * - Protects routes that require authentication.
 * - If the user is authenticated, renders the nested route (`<Outlet />`).
 * - If not authenticated, redirects the user to the login page.
 * - If still loading auth state, renders a loading screen.
 * 
 * @returns {JSX.Element} - The outlet for protected routes or a redirect to login.
 */
const PrivateRoute = () => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;

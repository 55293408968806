import IconEmail from '../assets/icons/email.svg';
import IconEmailWhite from '../assets/icons/email_white.svg';
import LockIcon from '../assets/icons/lock.svg';
import PersonIcon from '../assets/icons/person.svg';
import CheckWhite from '../assets/icons/check_white.svg';
import DateIcon from '../assets/icons/date_calendar.svg';
import NextIcon from '../assets/icons/next.svg';
import LoadingIcon from '../assets/icons/simple_loading.svg';
import PhoneIcon from '../assets/icons/phone.svg';
import PasswordIcon from '../assets/icons/password.svg';
import IcognitoIcon from '../assets/icons/icognito.svg';
import ArrowRight from '../assets/icons/stash_arrow-right-solid.svg';
import CodeIcon from '../assets/icons/code.svg';
import SuccessRecoveryIcon from '../assets/icons/Security-cuate 1.svg';

export const icons: Record<string, string> = {
    email: IconEmail,
    emailWhite: IconEmailWhite,
    lock: LockIcon,
    person: PersonIcon,
    checkWhite: CheckWhite,
    date: DateIcon,
    next: NextIcon,
    loading: LoadingIcon,
    phone: PhoneIcon,
    password: PasswordIcon,
    icognito: IcognitoIcon,
    arrowRight: ArrowRight,
    code: CodeIcon,
    successRecoveryIcon: SuccessRecoveryIcon
};

/**
 * Applies a mask to a phone number, formatting it as (XX) XXXXX-XXXX or (XX) XXXX-XXXX.
 * @param {string} value - The unformatted phone number.
 * @returns {string} The formatted phone number.
 */
export const maskPhone = (value: string): string => {
    value = value.replace(/\D/g, "");

    return value.length > 10
        ? `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
        : `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
};

/**
 * Applies a mask to a CPF (Brazilian Individual Taxpayer Registry) number.
 * Formats it as XXX.XXX.XXX-XX.
 * @param {string} value - The unformatted CPF number.
 * @returns {string} The formatted CPF.
 */
export const maskCPF = (value: string): string => {
    value = value.replace(/\D/g, "");

    return value
        .replace(/^(\d{3})(\d)/, "$1.$2")
        .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
        .replace(/\.(\d{3})(\d)/, ".$1-$2");
};


/**
 * Applies a mask to a recovery code, formatting it as XXX-XXX.
 * @param {string} value - The unformatted recovery code.
 * @returns {string} The formatted recovery code.
 */
export const maskRecoveryCode = (value: string): string => {
    value = value.replace(/\D/g, "");
    return value.replace(/^(\d{3})(\d{0,3})/, "$1-$2").slice(0, 7);
};

import { toast } from 'react-toastify';
import { useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import AuthForm from '../../components/forms/AuthForm';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/**
 * Register Page Component
 *
 * - Handles user registration process.
 * - Displays success/error messages using toast notifications.
 * - Manages loading and success states.
 */
const Register = () => {
    const { register } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    /**
     * Handles the user registration process.
     * @param formData User registration data from the form.
     * @returns {Promise<boolean>} Indicates whether registration was successful.
     */
    const handleRegister = async (formData: Record<string, string>): Promise<boolean> => {
        setIsLoading(true);
        setIsSuccess(false);

        try {
            const userData = {
                first_name: formData.firstName,
                last_name: formData.lastName,
                cpf: formData.cpf.replace(/\D/g, ""),
                email: formData.email,
                password: formData.registerPassword,
                phone_number: formData.phone.replace(/\D/g, ""),
                birthday: formData.dateOfBirth,
            };

            await register(userData);

            toast.success("Cadastro realizado com sucesso! 🎉");

            setIsSuccess(true);

            setTimeout(() => navigate('/home'), 1000);
            return true;
        } catch (error: any) {
            console.error("Erro no cadastro:", error);

            let errorMessage = "Ocorreu um erro ao processar seu cadastro.";

            if (error.response?.data?.message) {
                errorMessage = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            toast.error(errorMessage);

            setIsLoading(false);
            return false;
        }
    };

    return (
        <AuthLayout>
            <AuthForm
                type="register"
                onSubmit={handleRegister}
                isLoading={isLoading}
                isSuccess={isSuccess}
            />
        </AuthLayout>
    );
};

export default Register;

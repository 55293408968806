import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthStep from "./AuthStep";
import { authConfig, AuthConfig } from "../../config/authConfig";
import Button from "./Button";
import logoTipo from '../../assets/icons/OnMind.svg';
import logoTipoAdmin from '../../assets/icons/OnMindAdmin.svg';
import { icons } from "../../utils/icons";
import GoogleButton from "./GoogleButton";
import { maskCPF, maskPhone, maskRecoveryCode } from "../../utils/masks";

interface AuthFormProps {
    type: keyof typeof authConfig;
    onSubmit: (formData: Record<string, string>) => Promise<boolean | void>;
    isLoading: boolean;
    isSuccess: boolean;
}

/**
 * AuthForm component
 *
 * - Renders a multi-step authentication form.
 * - Supports login, registration and password recovery flows.
 * - Manages form state, input masking, validation, and navigation.
 */
const AuthForm: React.FC<AuthFormProps> = ({ type, onSubmit, isLoading, isSuccess }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [step, setStep] = useState<number>(0);
    const [formData, setFormData] = useState<Record<string, string>>({});
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const config: AuthConfig = authConfig[type];

    /**
     * Handles input change with optional field-specific masking.
     */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        if (name === "phone") value = maskPhone(value);
        if (name === "cpf") value = maskCPF(value);
        if (name === "recoveryCode") value = maskRecoveryCode(value);

        setFormData({ ...formData, [name]: value });
    };

    /**
     * Validates current step by checking for empty required fields.
     */
    const validateStep = () => {
        const stepFields = config.steps[step]?.fields || [];
        let newErrors: Record<string, boolean> = {};

        stepFields.forEach((field) => {
            if (!formData[field.name]) {
                newErrors[field.name] = true;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    /**
     * Advances to the next step if validation passes.
     */
    const handleNextStep = () => {
        if (validateStep()) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    /**
     * Automatically advances the step if submission was successful.
     */
    useEffect(() => {
        if (isSuccess) {
            handleNextStep();
        }
    }, [isSuccess]);

    /**
     * Handles manual form submission.
     */
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateStep()) return;
        await onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="auth-form">
            <div className={`header-auth-form ${type === 'recovery' ? 'header-auth-recovery' : ''}`}>
                {type === 'recovery' && (
                    <Button
                        icon={<img src={icons.arrowRight} />}
                        text={'Voltar'}
                        template={2}
                        onClick={() => navigate('/login')}
                        iconDirection={'right'}
                        type="button"
                    />
                )}
                <img src={type == 'loginAdmin' ? logoTipoAdmin : logoTipo} className="auth-logotipo" alt="Logotipo" />
                {type === 'recovery' && <div></div>}
            </div>

            <h2 className={`auth-title ${type === 'recovery' ? 'auth-top-margin-title' : ''}`}>
                {config.steps[step]?.title}
            </h2>
            <h3 className="auth-subtitle">{config.steps[step]?.subtitle}</h3>

            {type === "recovery" && step === 3 && (
                <img src={icons.successRecoveryIcon} />
            )}

            {type !== 'recovery' && type !== 'loginAdmin' && (
                <div className="auth-toggle">
                    <button
                        type="button"
                        className={`toggle-btn ${location.pathname === '/cadastro' ? 'active' : ''}`}
                        onClick={() => navigate('/cadastro')}
                    >
                        Cadastro
                    </button>
                    <button
                        type="button"
                        className={`toggle-btn ${location.pathname === '/cadastro' ? '' : 'active'}`}
                        onClick={() => navigate('/login')}
                    >
                        Entrar
                    </button>
                </div>
            )}

            <AuthStep
                stepData={config.steps[step]}
                formData={formData}
                errors={errors}
                onSubmit={onSubmit}
                handleInputChange={handleInputChange}
                handleNextStep={handleNextStep}
                isLoading={isLoading}
                isSuccess={isSuccess}
            />

            {type === "login" && (
                <p className="auth-access-link">
                    Não se lembra da sua senha?{" "}
                    <a href="/recuperar-senha" onClick={(e) => { e.preventDefault(); navigate("/recuperar-senha"); }}>
                        <strong>Recuperar senha</strong>
                    </a>
                </p>
            )}

            {(type === "login" || type === "register") && (
                <p className="auth-access-link">
                    É um profissional?{" "}
                    <a href="/profissional" onClick={(e) => { e.preventDefault(); navigate("/profissional"); }}>
                        <strong>Acesso MindHub profissional</strong>
                    </a>
                </p>
            )}

            {(type === "loginAdmin") && (
                <p className="auth-access-link">
                    É um cliente?{" "}
                    <a href="/login" onClick={(e) => { e.preventDefault(); navigate("/login"); }}>
                        <strong>Acesso MindHub cliente</strong>
                    </a>
                </p>
            )}

            {type !== 'recovery' && type !== 'loginAdmin' && step !== 2 && (
                <>
                    <div className="access-to-google-mark">
                        <span></span>
                        <p> ou acesse com</p>
                        <span></span>
                    </div>
                    <GoogleButton onClick={() => {
                        window.location.href = `${import.meta.env.VITE_BACKEND_URL}/user/login/oauth`;
                    }} />


                </>
            )}

            {type === "recovery" && step === 0 && config.supportButton && (
                <>
                    <div className="access-to-google-mark">
                        <span></span>
                        <p>Não lembra do seu Email?</p>
                        <span></span>
                    </div>

                    <Button
                        icon={config.supportButton.icon ? <img src={icons[config.supportButton.icon]} /> : undefined}
                        text={config.supportButton.text}
                        template={2}
                        iconDirection={'right'}
                        onClick={() => navigate(config.supportButton?.path || "/")}
                    />
                </>
            )}

            {type === "recovery" && step === 1 && (
                <>
                    <div className="access-to-google-mark midle">
                        <span></span>
                        <p>Não chegou código no seu Email?</p>
                        <span></span>
                    </div>

                    <Button
                        icon={config.resendButton?.icon ? <img src={icons[config.resendButton.icon]} /> : undefined}
                        text={config.resendButton?.text || "Re-enviar"}
                        template={2}
                        iconDirection={'right'}
                        onClick={() => navigate(config.resendButton?.path || "/")}
                    />
                </>
            )}
        </form>
    );
};

export default AuthForm;

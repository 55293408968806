import api from './api';
import Cookies from 'js-cookie';

/**
 * Interface representing the structure of the authentication response.
 */
interface AuthResponse {
    success: boolean;
    user: {
        id: string;
        email: string;
        first_name: string;
        last_name: string;
    };
}

interface AdminResponse {
    success: boolean;
    admin: {
        id: string;
        email: string;
        first_name: string;
        last_name: string;
    };
}


/**
 * Interface for user registration data.
 */
interface RegisterData {
    first_name: string;
    last_name: string;
    cpf: string;
    email: string;
    password: string;
    phone_number: string;
    birthday: string;
}

/**
 * Interface for recovery email request.
 */
interface RecoverySendEmailData {
    emailRecovery: string;
}

/**
 * Interface for recovery code validation.
 */
interface VerifyRecoveryCodeData {
    recoveryCode: string;
}

/**
 * Interface for password reset request.
 */
interface ChangePasswordData {
    recoveryCode: string;
    newPassword: string;
}



/**
 * Logs in a user by sending credentials to the backend.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @returns A promise resolving to the authentication response.
 */
export const login = async (email: string, password: string): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/user/login', { email, password });

    return response.data;
};



/**
 * Logs in a user by sending credentials to the backend.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @returns A promise resolving to the authentication response.
 */
export const loginAdmin = async (email: string, password: string): Promise<AdminResponse> => {
    const response = await api.post<AdminResponse>('/admin/login', { email, password });

    return response.data;
};


/**
 * Registers a new user with provided data.
 * 
 * @param userData - Object containing registration fields.
 * @returns A promise resolving to the authentication response.
 */
export const register = async (userData: RegisterData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/user', userData);
    return response.data;
};

/**
 * Sends a recovery email for password reset.
 * 
 * @param data - Object containing the email to send recovery link/code.
 * @returns A promise resolving to the authentication response.
 */
export const recoverySendEmail = async (data: RecoverySendEmailData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/password-recovery/request', data);
    return response.data;
};

/**
 * Validates the recovery code sent to the user's email.
 * 
 * @param data - Object containing the recovery code.
 * @returns A promise resolving to the authentication response.
 */
export const verifyRecoveryCode = async (data: VerifyRecoveryCodeData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/password-recovery/validate', data);
    return response.data;
};

/**
 * Resets the user password using the recovery code.
 * 
 * @param data - Object containing the new password and recovery code.
 * @returns A promise resolving to the authentication response.
 */
export const changePassword = async (data: ChangePasswordData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/password-recovery/reset', data);
    return response.data;
};

/**
 * Logs out the current user and clears authentication cookies.
 * 
 * @returns A promise that resolves when logout is complete.
 */
export const logout = async (): Promise<void> => {
    await api.post('/user/logout');
    Cookies.remove('auth_token');
    Cookies.remove('user_email');
};

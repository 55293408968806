import { useNavigate } from "react-router-dom";

import '../../styles/pages/_welcome.scss';
import IconArrowAroundRight from '../../assets/icons/humbleicons_arrow-up.svg';
import LogoBlack from '../../assets/icons/OnMindBlack.svg';
import OrganicBlue from '../../assets/icons/OrganicBlue.svg';
import FamilyWelcome from '../../assets/images/family-welcome.png';
import Origami from '../../assets/images/origami.png';
import Button from "../../components/forms/Button";
import HelpCenter from '../../components/ui/HelpCenter';

const Welcome = () => {
    const navigate = useNavigate();

    return (
        <div className='welcome-container'>
            <img src={OrganicBlue} className="organic-shape desktop" />

            <header>
                <img src={LogoBlack} />
                <button
                    type='button'
                    onClick={() => navigate('/login')}
                >Entrar</button>
            </header>


            <div className='welcome-content'>
                <div className="welcome-left">
                    <h1>Serviços de</h1>
                    <h2>Saúde mental & Tele-atendimento</h2>
                    <h3>Agendamento de consultas online com psicológos e psiquiatras profissionais</h3>
                    <Button
                        text={'Agendar uma consulta'}
                        type={'button'}
                        iconDirection="right"
                        onClick={() => navigate('/login')}
                        icon={<img src={IconArrowAroundRight} />}
                    />

                    <HelpCenter
                        className={'help-center-welcome'}
                    />
                </div>
                <div
                    className="welcome-right"
                    style={{
                        color: "red",
                        backgroundImage: `url(${Origami})`,
                    }}
                >
                    <img src={FamilyWelcome} />
                </div>

            </div>
        </div>
    );
};

export default Welcome;

export interface AuthField {
    name: string;
    label: string;
    type: "text" | "email" | "password" | "date";
    icon: string;
    placeholder?: string;
}

export interface AuthButton {
    text: string;
    type: "button" | "submit";
    action?: string;
    icon?: string;
    path?: string;
}


export interface AuthStepConfig {
    title: string;
    subtitle: string;
    fields: AuthField[];
    buttons: AuthButton[];
}

export interface AuthConfig {
    steps: AuthStepConfig[];
    footerLinks?: { text: string; path: string }[];
    supportButton?: AuthButton;
    resendButton?: AuthButton;
}
export const authConfig: Record<string, AuthConfig> = {
    login: {
        steps: [
            {
                title: "Olá de novo!",
                subtitle: "Por favor, insira suas informações",
                fields: [
                    { name: "email", label: "Email", type: "email", icon: "email", placeholder: "email.example@mail.com" },
                    { name: "password", label: "Senha", type: "password", icon: "lock", placeholder: "********" },
                ],
                buttons: [{ text: "Entrar", type: "submit", icon: "checkWhite" }]
            }
        ],
        footerLinks: [
            { text: "Recuperar senha", path: "/recuperar-senha" },
            { text: "Acesso MindHub profissional", path: "/profissional" }
        ]
    },
    loginAdmin: {
        steps: [
            {
                title: "Olá de novo!",
                subtitle: "Por favor, insira suas informações",
                fields: [
                    { name: "email", label: "Email", type: "email", icon: "email", placeholder: "email.example@mail.com" },
                    { name: "password", label: "Senha", type: "password", icon: "lock", placeholder: "********" },
                ],
                buttons: [{ text: "Entrar", type: "submit", icon: "checkWhite" }]
            }
        ],
        footerLinks: [
            { text: "Acesso MindHub cliente", path: "/login" }
        ]
    },
    register: {
        steps: [
            {
                title: "Boas-vindas!",
                subtitle: "Vamos iniciar seu cadastro!",
                fields: [
                    { name: "email", label: "Email", type: "email", icon: "email", placeholder: "email.example@mail.com" },
                    { name: "firstName", label: "Primeiro Nome", type: "text", icon: "person", placeholder: "Seu primeiro nome" },
                    { name: "lastName", label: "Sobrenome", type: "text", icon: "person", placeholder: "Seu sobrenome" },
                    { name: "dateOfBirth", label: "Data de Nascimento", type: "date", icon: "date", placeholder: "00/00/0000" }
                ],
                buttons: [{ text: "Próxima etapa", type: "button", action: "nextStep", icon: "next" }]
            },
            {
                title: "Quase lá...",
                subtitle: "Só precisamos de algumas informações",
                fields: [
                    { name: "phone", label: "Telefone", type: "text", icon: "phone", placeholder: "(00) 00000-0000" },
                    { name: "cpf", label: "CPF", type: "text", icon: "person", placeholder: "000.000.000-00" },
                    { name: "registerPassword", label: "Senha", type: "password", icon: "lock", placeholder: "********" },
                    { name: "confirmRegisterPassword", label: "Confirmar Senha", type: "password", icon: "lock", placeholder: "********" }
                ],
                buttons: [{ text: "Finalizar Cadastro", type: "submit", icon: "checkWhite" }]
            }
        ],
        footerLinks: [
            { text: "Acesso MindHub profissional", path: "/profissional" }
        ]
    },
    recovery: {
        steps: [
            {
                title: "Recuperar senha",
                subtitle: "Por favor, insira suas informações",
                fields: [
                    { name: "emailRecovery", label: "Email", type: "email", icon: "email", placeholder: "email.example@mail.com" }
                ],
                buttons: [{ text: "Enviar", type: "button", icon: "emailWhite", action: "onSubmit" }]
            },
            {
                title: "Recuperar senha",
                subtitle: "Insira o código enviado para o seu e-mail",
                fields: [
                    { name: "recoveryCode", label: "Código de recuperação", type: "text", icon: "code", placeholder: "000-000" }
                ],
                buttons: [{ text: "Próxima etapa", type: "button", icon: "next", action: "verifyCode" }]
            },
            {
                title: "Recuperar senha",
                subtitle: "Crie uma nova senha",
                fields: [
                    { name: "newPassword", label: "Senha", type: "password", icon: "lock", placeholder: "********" },
                    { name: "confirmNewPassword", label: "Repita a senha", type: "password", icon: "lock", placeholder: "********" }
                ],
                buttons: [{ text: "Concluir", type: "submit", icon: "checkWhite", action: "changePassword" }]
            },
            {
                title: "Senha salva!",
                subtitle: "Sua conta foi atualizada com a nova senha",
                fields: [],
                buttons: [{ text: "Entrar", type: "button", icon: "checkWhite", action: "goToLogin" }]
            }
        ],
        supportButton: {
            text: "Falar com suporte",
            type: "button",
            icon: "icognito",
            path: "/suporte"
        },
        resendButton: {
            text: "Re-enviar",
            type: "button",
            icon: "email",
            path: "/reenviar-codigo"
        }
    }
};

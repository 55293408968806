import OnMindWhite from '../../assets/icons/OnMindWhite.svg';
import MenuHamburguer from '../ui/MenuHamburguer';


const Header = () => {
    return (
        <header>
            <img src={OnMindWhite} />
            <MenuHamburguer />

        </header>
    );
};

export default Header;

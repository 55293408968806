import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const OAuthCallback = () => {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const called = useRef(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const { data } = await api.get("/user/me");

                Cookies.set("auth_token", data.id, { secure: true, sameSite: "strict" });
                Cookies.set("user_infos", JSON.stringify(data), {
                    secure: true,
                    sameSite: "strict",
                });
                setUser(data);
                toast.success("Login com Google realizado com sucesso!");
                navigate("/home");
            } catch (err) {
                toast.error("Erro ao autenticar com Google.");
                navigate("/login");
            }
        };

        if (!user && !called.current) {
            called.current = true;
            fetchUser();
        } else if (user) {
            navigate("/home");
        }
    }, []);

    return <p></p>;
};

export default OAuthCallback;

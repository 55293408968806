import React, { useState } from 'react';
import CheckIcon from '../../assets/icons/check.svg';
import EyeIcon from '../../assets/icons/eye.png';
import Hidden from '../../assets/icons/hidden.png';

interface InputProps {
    label: string;
    type?: 'text' | 'email' | 'password' | 'date';
    value: string;
    name: string;
    className: string;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: React.ReactNode;
    actionIcon?: React.ReactNode;
    onActionClick?: () => void;
    isValid?: boolean;
    readOnly?: boolean;
}

const Input = ({ label, type = 'text', readOnly, name, value, className = 'input', placeholder, onChange, icon, actionIcon, onActionClick, isValid }: InputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div
            className={`component-input-wrapper ${className}`}>

            {icon && <span className="input-icon">{icon}</span>}


            <div className="input-container">
                <label className="input-label">{label}</label>

                <input
                    type={type === 'password' && showPassword ? 'text' : type}
                    value={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    name={name}
                    placeholder={placeholder}
                />



            </div>
            {type === 'password' ? (
                <span className="input-action" onClick={handleTogglePassword}>
                    {showPassword ? <img src={Hidden} /> : <img src={EyeIcon} />}
                </span>
            ) : (
                actionIcon && (
                    <span className="input-action" onClick={onActionClick}>
                        {actionIcon}
                    </span>
                )
            )}
            {isValid && <span className="input-valid"><img src={CheckIcon} /></span>}
        </div>
    );
};

export default Input;

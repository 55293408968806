import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import App from './App';

/**
 * Entry point for the React application.
 *
 * - Uses `StrictMode` to highlight potential issues in development.
 * - Applies global styles from `main.scss`.
 * - Mounts the `App` component inside the `root` DOM element.
 */

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../pages/public/Login';
import AdminLogin from '../pages/public/AdminLogin';
import Register from '../pages/public/Register';
import Welcome from '../pages/public/Welcome';
import RecoveryPassword from '../pages/public/RecoveryPassword';
import Dashboard from '../pages/admin/home/Dashboard';
import PrivateRoute from './PrivateRoute';
import OAuthCallback from '../pages/public/OAuthCallback';

/**
 * Application Router Configuration.
 *
 * - Defines public and private routes.
 * - Uses `PrivateRoute` to protect the dashboard.
 */
const AppRouter = () => (
    <Router basename="/">
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/cadastro" element={<Register />} />
            <Route path="/recuperar-senha" element={<RecoveryPassword />} />
            <Route path="/oauth-callback" element={<OAuthCallback />} />

            <Route element={<PrivateRoute />}>
                <Route path="/home" element={<Dashboard />} />
            </Route>

            <Route path="*" element={<Welcome />} />
        </Routes>
    </Router>
);

export default AppRouter;

import React from 'react';
import HelloImage from '../../assets/images/login/hello.jpg';

interface AuthLayoutProps {
    children: React.ReactNode;
    className?: string;
}

/**
 * AuthLayout Component
 * 
 * - Provides a consistent layout for authentication pages (Login, Register, etc.).
 * - Divides the page into two sections: form area and image area.
 * 
 * @param {React.ReactNode} children - The authentication form components.
 * @param {string} [className] - Optional additional class for the main container.
 * @returns JSX.Element
 */
const AuthLayout = ({ children, className }: AuthLayoutProps) => {
    return (
        <main className={`main-auth ${className ?? ''}`}>
            <div className="main-auth-container">
                <div className="main-auth-desc">
                    {children}
                </div>

                <div
                    className="main-auth-pic"
                    style={{
                        backgroundImage: `url(${HelloImage})`,
                    }}
                />
            </div>
        </main>
    );
};

export default AuthLayout;
